<template>
<ValidationProvider :name="field_name" :rules="rules" v-slot="{ classes, errors }">
  <div class="input-validate" :class="classes">
    <label class="text-gray-700 text-sm font-bold pb-2 font-serif">{{ field_name }}<b  v-if="new RegExp('(required)').test(rules)" class="text-red-500 ml-1">*</b></label>
    <textarea
      v-bind="$attrs"
      @input="$emit('update', $event.target.value)"
      v-on="$listeners" 
      class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" v-bind:class="{ 'hover:border-gray-400': new RegExp('(required)').test(rules) }" />
    <span>{{ errors[0] }}</span>
  </div>
</ValidationProvider>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update',
  },
  props: {
    field_name: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    }
  },
}
</script>