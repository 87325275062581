export default {

    handleError(error = null, message = 'Något problem uppstod', position = 'top-right', timeout = 5000) {
        if(message != '') this.$toasted.show(message, { 
            type : 'error', theme: 'outline', position: position, 
            duration: timeout,
            action : {
              text : 'X', class: 'font-sans', onClick : (e, toastObject) => { toastObject.goAway(0); }
            }
        });

        // if(process.env.VUE_APP_ENV === 'development' && error != null) console.error(error);
        console.error(error);

        if(error != null) {
          this.axios.post(`${process.env.VUE_APP_SERVER_URL}/frontend_error`, {
            message: error.message || "",
            stack: error.stack || "",
            url: this.$route.fullPath,
          });
        }
    },

    handleSuccess(message = 'Åtgärd slutförts', position = 'top-right', timeout = 5000) {
        this.$toasted.show(message, { 
            type : 'success', theme: 'outline', position: position, 
            duration: timeout,
            action : {
                text : 'X', class: 'font-sans', onClick : (e, toastObject) => { toastObject.goAway(0); }
            }
        });
    },

    setPageTitle(title = '', routeName) {
        if(this.$route.name == routeName) this.$store.commit("SET_PAGE_TITLE", title);
    },

    formatDate(date) {
      return this.moment(date).tz("Europe/Stockholm").format("ddd, YYYY-MM-DD, HH:mm");
    },
    
};