<template>
  <div>
    <div class="min-h-screen">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'main-layout',
  props: {
    pageTitle: {
      type: String
    }
  },
  components: {
    
  },
  data() {
    return {
      page_title: this.pageTitle
    }
  },
  methods: {
      
  },
  computed: {
    
  },
  mounted() {
    
  }
}
</script>
