<template>
  <div class="securpos">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white w-full md:w-1/2 lg:w-1/3 p-8 md:p-12 mx-8 rounded-lg shadow-xl">
        <div>
          <img class="logo w-32 mx-auto" :src="require('@/assets/logos/logo_horizontal.png')">
        </div>
        <div class="mt-8">
          <main v-if="!isLoggedIn">
            <section class="mt-8">
              <ValidationObserver ref="formLoginSecurPos">
                <div class="flex flex-col">
                  <div class="w-full">
                    <BaseInput v-model.trim="devicePhone" type="text" field_name="Telematiknummer" rules="required" v-on:keyup.enter="verifyDevicePhone()" />
                  </div>
                  <button class="btn-blue px-2 py-3 text-sm mt-5" @click.prevent="verifyDevicePhone()">
                    Logga in
                    <BaseIcon icon="key" class="ml-2" />
                  </button>
                </div>
              </ValidationObserver>
            </section>
          
          </main>
          <main v-if="isLoggedIn">
              <section v-if="!visit" class="mt-8">
                <ValidationObserver ref="formVisitSecurPos">
                  <div class="flex flex-col">
                    <div class="w-full py-1">
                      <BaseInput v-model="address.area" type="text" maxlength="30" field_name="Adress" />
                    </div>
                    <div class="w-full py-1">
                      <BaseInput v-model.trim="address.floor" type="number" field_name="Våning" />
                    </div>
                    <div class="w-full py-1">
                      <BaseInput v-model="address.building" type="text" maxlength="20" field_name="Namn på LGH" />
                    </div>
                    <div class="w-full py-1">
                      <BaseInput v-model="address.visitor" type="text" maxlength="20" field_name="Användare" />
                    </div>

                    <div class="border-b border-secondary-400 my-5"></div>

                    <div class="w-full py-1">
                      <label class="text-gray-600 text-sm font-bold font-serif">Timerlängd</label>
                      <div class="select-wrapper mt-2">
                        <select v-model="timeLength" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-1 py-1">
                          <option v-for="o in timeOptions" :key="o.value" :value="o.value">{{ o.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full py-1">
                      <label class="text-gray-600 text-sm font-bold font-serif">Timerns starttid</label>
                      <div class="datepicker-wrapper mt-2">
                        <date-picker v-model="startTime" type="time" format="HH:mm" :default-value="new Date()" :show-second="false" :editable="false" :clearable="false" placeholder="Nu" >
                        </date-picker>
                      </div>
                    </div>

                    <button class="btn-blue px-2 py-3 text-sm mt-12" @click.prevent="newVisit()">
                      Starta säkerhetstimern
                      <BaseIcon icon="hourglass-start" class="ml-2" />
                    </button>
                  </div>
                </ValidationObserver>
              </section>
              <section v-if="visit" class="mt-8">
                <div class="flex flex-col">
                  <div class="w-full text-center">
                    <BaseIcon icon="check-circle" class="text-accent-500 text-4xl" />
                  </div>
                  <div class="w-full text-center mt-2">
                    Vi önskar dig ett säkert hembesök
                  </div>
                  <div class="w-full text-sm mt-3">
                    Besöksadress: {{ visit.address.area }} {{ visit.address.floor }} {{ visit.address.building }} {{ visit.address.visitor }}<br>
                    Besökstid: {{ formatDate(visit.startTime) }}<br>
                    Förväntad vistelsetid: {{ timeToName(visit.timeLength) }}<br>
                  </div>

                  <div class="border-b border-secondary-400 my-5"></div>

                  <div class="w-full">
                    <div class="clear-both">
                      <div class="float-left">
                          <button class="btn-red-outline" @click.prevent="cancelVisitPrompt(visit)">
                            Avbryt besök
                          </button>
                      </div>
                      <div class="float-right">
                          <button class="btn-green" @click.prevent="changeVisit()">
                            Ändra besök
                          </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/sv';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

export default {
  name: 'SecurPos',
  title () {
    return `SecurPos`;
  },
  components: {
    DatePicker
  },
  data() {
    return {
      isLoading: false,
      isLoggedIn: false,
      devicePhone: '',
      timeLength: 1800,
      hasActiveVisit: false,
      timeOptions: [
        {  name: '15 minuter', value: 60*15 },
        {  name: '30 minuter', value: 60*30 },
        {  name: '1 timme', value: 60*60 },
        {  name: '2 timmar', value: 60*60*2 },
        {  name: '3 timmar', value: 60*60*3 }, //10800
        {  name: '4 timmar', value: 60*60*4 },
        {  name: '6 timmar', value: 60*60*6 },
        {  name: '8 timmar', value: 60*60*8 },
        {  name: '10 timmar', value: 60*60*10 },
        {  name: '12 timmar', value: 60*60*12 },
      ],
      address: {
        area: '',
        floor: '',
        building: '',
        visitor: ''
      },
      startTime: null,
      visit: null,
    }
  },

  methods: {
    
    async verifyDevicePhone() {
      this.$refs.formLoginSecurPos.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          this.isLoading = true;
          let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/securpos/verify/${this.devicePhone}`);
          this.isLoading = false;
          
          if(response.data.error) this.handleError(null, response.data.message);
          if(response.data.success) {
            this.isLoggedIn = true;
            this.initstartTime();
          }

        } catch (error) {
          this.isLoading = false;
          this.isLoggedIn = false;
          this.visit = null;

          this.handleError(error);
        }
      });
    },

    async newVisit() {
      this.$refs.formVisitSecurPos.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {

          let data = {
            devicePhone: this.devicePhone,
            address: this.address,
            startTime: this.startTime,
            timeLength: this.timeLength,
          };

          this.isLoading = true;
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/securpos/visit/new`, data);
          this.isLoading = false;
          
          if(response.data.error) this.handleError(null, response.data.message);
          if(response.data.success) this.visit = response.data.visit;

          localStorage.setItem("visit_status", "active");
          localStorage.setItem("visit_data", JSON.stringify(response.data.visit));

        } catch (error) {
          this.isLoading = false;
          this.handleError(error);
        }
      });
    },

    timeToName(value) {
      let t = _.find(this.timeOptions, {  value: value });
      if(t) return t.name;
      else return '';
    },

    cancelVisitPrompt(visit) {
      this.$modal.show('dialog', {
        title: `Avboka ditt besök den <span class="text-red-500">${this.formatDate(visit.startTime)}</span>?`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">NEJ</div>',
            handler: () => {
              this.$modal.hide('dialog');
            }
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">JA</div>',
            handler: () => {
              this.cancelVisit(visit._id);
            }
          }
        ]
      });
    },

    async cancelVisit(id) {
      try {
        this.isLoading = true;
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/securpos/visit/cancel/${id}`);
        this.isLoading = false;

        this.$modal.hide('dialog');
        this.handleSuccess('Besöket ställs in');

        this.changeVisit()

      } catch (error) {
        this.isLoading = false;
        this.$modal.hide('dialog');
        this.handleError(error);
      }
    },
    async isVisitExist(){

      this.hasActiveVisit = false;
      let visit_status = localStorage.getItem("visit_status");
      let visit_data = localStorage.getItem("visit_data");

     
      

      if(visit_data !== null){
        visit_data = JSON.parse(visit_data);
        this.hasActiveVisit = true;

        if(visit_status === 'active'){
          let startTime = this.moment(visit_data.startTime);

          if (visit_data.timeLength > moment.utc().diff(startTime, 'seconds')) {
            this.isLoggedIn = true;
            this.visit = visit_data
          }else{
            this.hasActiveVisit = false
            this.isLoggedIn = false
            this.visit = false
          }
        }
      }
    },
    changeVisit() {
      this.hasActiveVisit = false; 
      this.isLoggedIn = false; 
      this.visit = null;
      localStorage.setItem("visit_status", null);
      localStorage.setItem("visit_data", null);

      this.resetForm();
    },

    resetForm() {
      this.address = {
        area: '',
        floor: '',
        building: '',
        visitor: ''
      };
      this.startTime = null;
      this.timeLength = 1800;
    },

    formatDate(date) {
      if(date) return this.moment(new Date(date)).format("YYYY-MM-DD HH:mm");
      else return '';
    },

    initstartTime() {
      // todo: date always current or future. future set by user
      // this.startTime = new Date();
    },

  },

  created() {
    this.initstartTime();
    this.isVisitExist()
  }

}
</script>