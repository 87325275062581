import Vue from 'vue'
import App from './App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import axios from '@/utils/axios_base';
import helpers from '@/utils/helpers';
import title_mixin from '@/utils/title_mixin';
// library
import NProgress from 'vue-nprogress'
import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/utils/input_validation';
import _ from 'lodash'; // eslint-disable-line
import moment from 'moment-timezone';
import 'moment/locale/sv';
// components
import base_input from '@/components/base/_base_input';
import base_textarea from '@/components/base/_base_textarea';
import base_select from '@/components/base/_base_select';
import base_switch from '@/components/base/_base_switch';
import base_icon from '@/components/base/_base_icon';
import loading_spinner from '@/components/base/loading_spinner';

// nprogress
Vue.use(NProgress, {
  latencyThreshold: 50, 
  router: false,
  http: false
});

const nprogress = new NProgress()

axios.interceptors.request.use(function (config) {
  nprogress.start();
  return config;
}, function (error) {
  nprogress.done();
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  nprogress.done();
  return response;
}, function (error) {
  nprogress.done();
  return Promise.reject(error);
});

// global axios
Vue.prototype.axios = axios;

// global moment
moment.locale('sv');
Vue.prototype.moment = moment;

// plugins
Vue.use(Toasted);
Vue.use(VModal, { dialog: true });

// external components
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// internal components
Vue.component('BaseInput', base_input);
Vue.component('BaseTextarea', base_textarea);
Vue.component('BaseSelect', base_select);
Vue.component('BaseSwitch', base_switch);
Vue.component('BaseIcon', base_icon);
Vue.component('LoadingSpinner', loading_spinner);

// mixins
Vue.mixin({
  methods: helpers
});
Vue.mixin(title_mixin);

Vue.config.productionTip = false

new Vue({
  nprogress,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
