<template>
<!-- eslint-disable -->
    <Loading :active.sync="isLoading" 
        loader="spinner" 
        color="#1a8de9" 
        background-color="#eee" 
        :height="64" 
        :width="64" />
</template>

<script>
/* eslint-disable */
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: {
        Loading
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
}
</script>