<template>
  <div id="app">
    <NprogressContainer/>
    <div v-if="deferredPrompt" class="px-1 py-1 flex">
      <div class="w-full clear-both">
        <div class="float-right">
          <button @click.prevent="install" class="btn-blue">
            Installera
            <BaseIcon icon="download" class="ml-1" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="updateExists" class="px-1 py-1 flex">
      <div class="w-full clear-both">
        <div class="float-right">
          <button @click.prevent="refreshApp" class="btn-blue">
            Uppdatera
            <BaseIcon icon="download" class="ml-1" />
          </button>
        </div>
      </div>
    </div>
    <MainLayout/>
    <v-dialog />
  </div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import MainLayout from '@/components/layout';

export default {
  components: {
    NprogressContainer,
    MainLayout
  },
  data() {
    return {
      // install app
      deferredPrompt: null,
      // update app
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.updateExists = false;

      if (!this.registration || !this.registration.waiting) return;

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
  created() {
    var vm = this;
    // install
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    // update
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    if(navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (vm.refreshing) return;

        vm.refreshing = true;
        window.location.reload();
      })
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import "./src/styles/global.scss";
</style>